import { RedocStandalone } from 'redoc';

function App() {
  return (
    <RedocStandalone
      specUrl={"https://production.monarcha.in/explorer/openapi.json"}
      // specUrl={'http://localhost:3123/openapi.json'}
      options={{
        nativeScrollbars: true,
        theme: { colors: { primary: { main: '#1a4677' } } },
        hideDownloadButton: true,
      }}
    />
  );
}

export default App;
